@tailwind components;
@tailwind utilities;

:root {
  --p-first: #0A3E62;
  --p-second: #1A5883;
  --p-third: #2E709D;
}

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
}

body {
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
  /*padding-top:36px;*/
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: Jost;
  background: radial-gradient(50% 50% at 50% 50%, #094D7E 0%, #0A3C60 100%);
  min-height: 100vh; /*95vh;*/
  height: 100%;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
}

body.modal-open {
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

#root {
  min-height: 100vh;
}

h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 96px;
  line-height: 112px;
  letter-spacing: -1.5px;
  color: rgba(0, 0, 0, 0.87);
}

h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.5px;
  color: rgba(0, 0, 0, 0.87);
}

h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  color: rgba(0, 0, 0, 0.87);
}

h4 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.87);
}

.h7 {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 1.25px;
  color: #000000;
}

.h8 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.5px;
  color: rgba(0, 0, 0, 0.87);
}


a {
  text-decoration: none;
  color: inherit;
}

.page {
  min-height: 75vh;
}